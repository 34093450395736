import React from "react"
import { Container, Row } from "reactstrap"
import { StaticQuery, graphql } from "gatsby"
import { map } from "lodash"
import EventCard from "./EventCard"

const EventList = () => {
  return (
    <StaticQuery
      query={graphql`
        query eventListQuery {
          allStrapiEvent {
            nodes {
              date
              title
              slug
              location
              seo {
                metaDescription
                metaTitle
                shareImage {
                  localFile {
                    publicURL
                    childImageSharp {
                      fixed(width: 400, height: 200) {
                        src
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={({ allStrapiEvent }) => {
        return (
          <section className="section">
            <Container>
              <Row>
                {map(allStrapiEvent.nodes, (data, idx) => {
                  return <EventCard key={idx} data={data} />
                })}
              </Row>
            </Container>
          </section>
        )
      }}
    />
  )
}

export default EventList
