import React from "react"
import { get } from "lodash"
import { Col, Card, CardBody, CardImg } from "reactstrap"

import { withPrefix, Link } from "../../utils"

const EventCard = props => {
  const { data, key } = props
  if (!data) return <div />
  const {
    seo: { metaTitle, metaDescription, keywords, shareImage },
  } = data
  let imgSrc = get(shareImage, "localFile.childImageSharp.fixed.src", null)
  let url = `events/${data.slug.replace(".", "").toLowerCase()}`
  let oneKeyword = keywords && keywords.split(",")[0]
  return (
    <Col lg="4" md="6" className="mb-4 pb-2" key={key} name="event">
      <Link to={withPrefix(url)} className="title h6 text-dark">
        <Card className="job-box rounded shadow border-0 overflow-hidden ">
          <div className="position-relative">
            <CardImg top src={imgSrc} className="rounded-top" alt="" />
            {oneKeyword > 0 && (
              <ul className="list-unstyled head mb-0" key={oneKeyword}>
                <li className="badge badge-danger badge-pill">{oneKeyword}</li>
              </ul>
            )}
          </div>

          <CardBody className="content">
            <h5>
              <Link to={withPrefix(url)} className="card-title title text-dark">
                {metaTitle}
              </Link>
            </h5>
            <p className="text-muted">{metaDescription}</p>
          </CardBody>
        </Card>
      </Link>
    </Col>
  )
}

export default EventCard
