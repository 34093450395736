import React from "react"
import Loadable from "@loadable/component"
const SectionCta = Loadable(() => import("./SectionCta"))
const SectionFaq = Loadable(() => import("./SectionFaq"))
const SectionFeatures = Loadable(() => import("./SectionFeatures"))
const SectionHero = Loadable(() => import("./SectionHero"))
const SectionPricing = Loadable(() => import("./SectionPricing"))
const SectionReviews = Loadable(() => import("./SectionReviews"))
const SectionTeam = Loadable(() => import("./SectionTeam"))
const SectionRecentPosts = Loadable(() => import("./SectionRecentPosts"))
const SectionCloudCastles = Loadable(() => import("./SectionCloudCastles"))

const facebookAppId = "111705266998380"

// Map Strapi sections to section components
const sectionComponents = {
  "sections.hero": SectionHero,
  "sections.features": SectionFeatures,
  "sections.reviews": SectionReviews,
  "sections.pricing": SectionPricing,
  "sections.call-to-action": SectionCta,
  "sections.profiles": SectionTeam,
  "sections.fa-qs": SectionFaq,
  "sections.recent-posts": SectionRecentPosts,
  "sections.cloud-castles": SectionCloudCastles,
}

// Display a section individually
const Section = props => {
  // Prepare the component
  const SectionComponent = sectionComponents[props.type]

  if (!SectionComponent) {
    return null
  }

  // Display the section
  return <SectionComponent {...props} key={props.key} />
}

// Display the list of sections
const Sections = ({ sections, path }) => {
  if (path === "/") {
    // in case of homepage we will just add the recent posts section
    sections.splice(sections.length - 1, 0, {
      strapi_component: "sections.pricing",
    })
    sections.splice(sections.length - 1, 0, {
      strapi_component: "sections.recent-posts",
    })
  } else if (path === "/partners/cloud-castles") {
    sections.splice(sections.length - 1, 0, {
      strapi_component: "sections.cloud-castles",
    })
  }

  return (
    <React.Fragment>
      {/* Show the actual sections */}
      {sections &&
        sections.map((section, section_idx) => (
          <Section
            key={section_idx}
            data={section}
            type={section.strapi_component}
          />
        ))}
    </React.Fragment>
  )
}

export default Sections
