import React, { useEffect, useState } from "react"
import { get, map } from "lodash"
import clsx from "clsx"
import { htmlToReact, withPrefix, Link } from "../../utils"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { SectionTitle, ProfileCard } from "../../components/shared"
import imgbg from "../../assets/images/account/bg.png"
import Slider from "react-slick"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const SectionSponsors = props => {
  const [isRendered, setIsRendered] = useState(false)

  useEffect(() => {
    setIsRendered(true)
  }, [])

  let section = get(props, "data", null)
  var settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  if (!isRendered) return <div />
  return (
    <section className={"section mt-md-5 mt-lg-4"}>
      {/* section title */}
      <SectionTitle title="Sponsors" />
      {/* Partners */}
      <Container>
        <Slider {...settings} className="justify-content-center p-2">
          {map(get(section, "sponsors", null), (sponsor, sponsor_idx) => (
            <Link
              to={withPrefix(`/partners/${sponsor.slug}`)}
              className={clsx("p-2", {
                "bg-light": sponsor.logoBackground === "light",
                "bg-primary": sponsor.logoBackground === "blue",
              })}
            >
              <img
                className="d-flex"
                src={sponsor.logo.localFile.publicURL}
                alt={sponsor.name}
              />
            </Link>
          ))}
        </Slider>
      </Container>
    </section>
  )
}
export default SectionSponsors
