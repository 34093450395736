import React from "react"
import { get, map } from "lodash"
import { htmlToReact, withPrefix, markdownify } from "../../utils"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { SectionTitle, ProfileCard } from "../../components/shared"

const SectionTalks = props => {
  let section = get(props, "data", null)
  return (
    <section className={"section mt-md-5 mt-lg-4"}>
      <SectionTitle
        title="Talks"
        description={htmlToReact(get(section, "subtitle", null))}
      />
      <Container>
        {map(get(section, "talks", null), (talk, talk_idx) => (
          <div className=" py-3">
            <Card className="rounded shadow border-0 bg-light overflow-hidden">
              <Row
                className="no-gutters align-items-center"
                style={{
                  flexDirection: talk_idx % 2 === 0 ? "row-reverse" : "",
                }}
              >
                <Col lg="3">
                  <ProfileCard profile={talk.speaker} />
                </Col>

                <Col lg="8">
                  <CardBody className="section-title p-md-5">
                    <h4 className="title mb-4">{talk.title}</h4>
                    <p className="text-muted para-desc mb-0">
                      {markdownify(talk.content)}
                    </p>
                    {/* <div className="mt-4 pt-2">
                      <a to="#" className="btn btn-outline-primary">
                        Read More <i className="mdi mdi-chevron-right"></i>
                      </a>
                    </div>*/}
                  </CardBody>
                </Col>
              </Row>
            </Card>
          </div>
        ))}
      </Container>
    </section>
  )
}
export default SectionTalks
