import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Sections from "../../components/sections"
import { Container, Row, Col, Button } from "reactstrap"
import PageBreadcrumb from "../../components/shared/PageBreadcrumb"
import { EventTalks, EventSponsors } from "../../components/events"
import ModalVideo from "react-modal-video"
import moment from "moment"
import { parseVideoURL } from "../../utils"

//CountDown
import Countdown from "react-countdown"

import bgImage from "../../assets/images/event/event.jpg"

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <div />
  } else {
    // Render a countdown
    return (
      <React.Fragment>
        <div className="count-down">
          <span className="count-number">{days}</span>
          <span className="count-head position-relative d-block">Days</span>
        </div>
        <div className="count-down">
          <span className="count-number">{hours}</span>
          <span className="count-head position-relative d-block">Hours</span>
        </div>{" "}
        <div className="count-down">
          <span className="count-number">{minutes}</span>
          <span className="count-head position-relative d-block">Minutes</span>
        </div>{" "}
        <div className="count-down">
          <span className="count-number">{seconds}</span>
          <span className="count-head position-relative d-block">Seconds</span>
        </div>
      </React.Fragment>
    )
  }
}
export const query = graphql`
  query EventQuery($slug: String!) {
    strapiGlobal {
      siteName
      favicon {
        localFile {
          publicURL
        }
      }
      defaultSeo {
        robots
        metaDescription
        metaTitle
        keywords
        shareImage {
          localFile {
            publicURL
          }
        }
      }
    }

    strapiEvent(slug: { eq: $slug }) {
      date
      location
      slug
      url
      recording_url
      seo {
        keywords
        metaDescription
        metaTitle
        robots
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      title
      sponsors {
        name
        logo {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
        }
        logoBackground
        slug
      }
      talks {
        content
        title
        speaker {
          name
          title
          picture {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
              publicURL
            }
          }
        }
      }
    }
  }
`

const Event = props => {
  const [isOpen, setOpen] = useState(false)
  const { strapiGlobal, strapiEvent } = props.data

  let eventCompleted = moment(strapiEvent.date).isBefore(moment.now())
  let recordingUrl = strapiEvent.recording_url
  let videoMetadata
  if (recordingUrl) {
    videoMetadata = parseVideoURL(recordingUrl)
  }
  return (
    <Layout seo={strapiEvent.seo} strapiGlobal={strapiGlobal}>
      <PageBreadcrumb
        page={{
          source: "events",
          showBreadcrumb: true,
          detailPage: true,
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg="10" className="text-center">
              <div className="title-heading">
                <h1 className="display-4 title-dark text-white font-weight-bold mb-3">
                  {strapiEvent.title}
                </h1>
                <Row>
                  <Col md="12" className="text-center">
                    <div id="eventdown">
                      <Countdown date={strapiEvent.date} renderer={renderer} />
                    </div>
                  </Col>
                </Row>

                {eventCompleted ? (
                  <div className="watch-video mt-4 pt-2">
                    <Button
                      onClick={() => setOpen(true)}
                      className="video-play-icon watch text-dark"
                    >
                      <i className="mdi mdi-play play-icon-circle text-center d-inline-block mr-2 rounded-pill title-dark text-white position-relative play play-iconbar"></i>{" "}
                      Watch Video
                    </Button>
                  </div>
                ) : (
                  <div className="mt-4 pt-2">
                    <a
                      href={strapiEvent.url}
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-success mt-2 mr-2"
                    >
                      <i className="mdi mdi-ticket"></i> Register
                    </a>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </PageBreadcrumb>
      <EventTalks data={strapiEvent} />
      <EventSponsors data={strapiEvent} />
      <Sections
        sections={strapiEvent.sections}
        path={props.path}
        isHomePage={false}
      />
      {videoMetadata && (
        <ModalVideo
          channel={videoMetadata.channel}
          isOpen={isOpen}
          videoId={videoMetadata.videoId}
          onClose={() => setOpen(false)}
        />
      )}
    </Layout>
  )
}
export default Event
